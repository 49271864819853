<!--
 * @Author: jiang
 * @Date: 2021-06-11 16:12:55
 * @Description: 任务落实
-->
<template>
  <div style=" width: 100%; height: 100%; padding: 0 15px 15px;">
    <b-section-scroll class="g-bg">
      <div
        slot="header"
        class="g-page-title"
      >{{ title }}</div>
      <el-form
        v-loading="loading"
        ref="form"
        class="g-p-20"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-row :gutter="10">
          <el-col>
            <el-form-item
              label="任务说明"
              prop="name"
            >
              <el-input
                v-model="formData.name"
                type="textarea"
                :autosize="{minRows: 3}"
                placeholder="输入内容"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="任务落实是否需上传附件"
              prop="medias_set"
            >
              <el-radio-group v-model="formData.medias_set">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="任务落实截止日期"
              prop="executive_end_data"
            >
              <el-date-picker
                style="width: 100%;"
                v-model="formData.executive_end_data"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="任务目录"
              prop="meeting_id"
            >
              <b-select-task-cate v-model="formData.meeting_id"></b-select-task-cate>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="任务落实部门"
              prop="executive_departments"
            >
              <b-select-dept
                v-model="formData.executive_departments"
                multiple
              ></b-select-dept>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="任务落实人员"
              prop="executive_users"
            >
              <b-select-user
                v-model="formData.executive_users"
                multiple
              ></b-select-user>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="图片">
              <c-upload-image v-model="formData.images"></c-upload-image>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="附件">
              <c-upload-media
                v-model="formData.media_keys"
                :default="defaultMedia"
              ></c-upload-media>
            </el-form-item>
          </el-col>
          <el-col class="g-center">
            <b-btn-confirm
              :loading="submitLoading"
              @click="onSubmit"
            ></b-btn-confirm>
          </el-col>
        </el-row>
      </el-form>
    </b-section-scroll>
  </div>
</template>

<script>
import CUploadImage from '@/components/upload/image'
import CUploadMedia from '@/components/upload/media'
import BSelectTaskCate from '@/components/select/cascader/task'
import BSelectDept from '@/components/select/cascader/dept'
import BSelectUser from '@/components/select/cascader/user'
import { createTask, updateTask, getTaskDetail } from '@/api/task'

export default {
  components: {
    CUploadImage,
    CUploadMedia,
    BSelectTaskCate,
    BSelectDept,
    BSelectUser,
  },
  data() {
    return {
      id: '',
      loading: false,
      submitLoading: false,
      defaultMedia: [],
      formData: {
        name: '',
        executive_end_data: '',
        executive_departments: [],
        supervisor_user_id: 1,
        executive_users: [],
        medias_set: 0,
        meeting_id: this.$route.query.cate,
        images: [],
        media_keys: [],
      },
      formRules: {
        name: [{ required: true, message: '请填写任务说明', trigger: 'blur' }],
        executive_end_data: [{ required: true, message: '请选择任务截至日期', trigger: 'change' }],
      },
    }
  },
  computed: {
    title() {
      if (this.$route.path === '/task/update') {
        return '更新任务'
      } else {
        return '创建任务'
      }
    },
  },
  watch: {
    '$route.path': {
      handler(path) {
        if (path === '/task/update') {
          this.id = this.$route.query.id
          this.fetchData()
        } else {
          this.defaultMedia = []
          this.id = null
          this.formData = {
            name: '',
            executive_end_data: '',
            executive_departments: [],
            supervisor_user_id: 1,
            executive_users: this.$route.query.userId ? [+this.$route.query.userId] : [],
            medias_set: 0,
            meeting_id: this.$route.query.cate ? +this.$route.query.cate : '',
            images: [],
            media_keys: [],
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      getTaskDetail(this.id)
        .then(res => {
          this.formData = {
            name: res.name,
            executive_end_data: res.executive_end_data,
            executive_departments: res.deparment_ids ? JSON.parse(res.deparment_ids) : [],
            executive_users: res.executive_users,
            supervisor_user_id: res.supervisor_user_id,
            images: res.images,
            media_keys: [],
            medias_set: res.medias_set,
            meeting_id: res.meeting_id,
            meeting_name: (res.meeting&&res.meeting.name)||"",
          }
          this.defaultMedia = res.media
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmit() {
      if (
        this.formData.executive_departments.length === 0 &&
        this.formData.executive_users.length === 0
      ) {
        this.$message.warning('任务落实部门和任务落实人员至少需要填写一个')
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const params = {
            name: this.formData.name,
            executive_end_data: this.formData.executive_end_data,
            executive_departments: this.formData.executive_departments,
            executive_users: this.formData.executive_users,
            supervisor_user_id: this.formData.supervisor_user_id,
            images: this.formData.images,
            media_keys: this.formData.media_keys,
            medias_set: this.formData.medias_set,
            meeting_id: this.formData.meeting_id,
          }
          if (this.id) {
            updateTask(this.id, params)
              .then(res => {
                this.$message.success('任务更新成功')
                this.$router.back()
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            createTask(params)
              .then(res => {
                this.$message.success('任务创建成功')
                this.$router.back()
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      })
    },
  },
}
</script>
